import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize } from 'rxjs';
import { baseUrl } from '../classes/general';
import { AuthService } from './auth.service';
import { LoadingService } from './loading.service';
import { EncryptDecryptService } from './encrypt-decrypt.service';
import { ToasterService } from './toaster.service';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  public navigateSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public vaultRateSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public onAllPermissionSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>({});

  public loginDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  public adminUsersDetailsSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>({});
  public adminPermissionSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>({});
  public onBankSortCodeSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    {}
  );

  public adminAuditLogSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    {}
  );
  constructor(
    private _http: HttpClient,
    private _Auth: AuthService,
    private _loading: LoadingService,
    private _encryptDecrypt: EncryptDecryptService,
    private _toasterService: ToasterService
  ) {
    this._Auth.loginSubject.asObservable().subscribe({
      next: (res) => {
        // //console.log(res);
        this.loginDataSubject.next(res?.result?.token);
      },
      error: (err) => {
        // //console.log(err.error);
      },
    });
  }

  // getBankSortCode() {
  //   this._loading.onOpenLoader();
  //   return this._http
  //     .get<any>(
  //       `${baseUrl}/api/fundtransfer/GetTransferDependencies/8880000001`
  //     )
  //     .pipe(
  //       finalize(() => {
  //         this._loading.onCloseLoader();
  //       })
  //     )
  //     .subscribe({
  //       next: (res) => {
  //         // //console.log(res);
  //         let result = this._encryptDecrypt.decrypt(res.data)
  //         this.onBankSortCodeSubject.next(result);
  //       },
  //       error: (err) => {
  //         // //console.log(err.error);
  //       },
  //     });
  // }
  onPasswordExpiryReminder() {
    return this._http
      .get<any>(`${baseUrl}/api/adminuser/admin/inApp/password-expiry-reminder`)
      .pipe()
      .subscribe({
        next: (res) => {
          let result = this._encryptDecrypt.decrypt(res.data);
          console.log(result);
          if (result.result) {
            this._toasterService.alert(
              'success',
              `${result.message} <br/> 
                <a style="text-decoration: underline; cursor: pointer;" routerLink="base/app/profile/overview/reset-password/overview"> <strong>goto Settings</strong></a>`,
              10000
            );
          }
        },
        error: (err) => {
          let result = this._encryptDecrypt.decrypt(err.error.data);
          // console.log(result);
          this._toasterService.alert('error', result.message, 10000);
          // console.log(err.error);
        },
      });
  }
  onAdminUsersDetails() {
    this.adminUsersDetailsSubject.next({});
    return this._http
      .get<any>(`${baseUrl}/api/adminuser/admin/details`)
      .pipe()
      .subscribe({
        next: (res) => {
          let result = this._encryptDecrypt.decrypt(res.data);
          this.adminUsersDetailsSubject.next(result);
        },
        error: (err) => {
          let result = this._encryptDecrypt.decrypt(err.error.data);
          // console.log(result);
          this._toasterService.alert('error', result.message, 10000);
          // console.log(err.error);
        },
      });
  }
  onAdminPermissionDetails() {
    this.adminPermissionSubject.next({});
    return this._http
      .get<any>(`${baseUrl}/api/permission/admin-users-permission`)
      .pipe()
      .subscribe({
        next: (res) => {
          let result = this._encryptDecrypt.decrypt(res.data);
          this.adminPermissionSubject.next(result);
        },
        error: (err) => {
          let result = this._encryptDecrypt.decrypt(err.error.data);
          // console.log(result);
          this._toasterService.alert('error', result.message, 10000);
          // console.log(err.error);
        },
      });
  }
  onAuditLog(page, pageSize, searchValue, from, to) {
    this._loading.onOpenLoader();
    let url = `${baseUrl}/api/auditlog/admin-user-activities`;
    let first = true;
    //   //console.log(reservationId, category)
    if (page || pageSize || searchValue || from || to) {
      url = `${baseUrl}/api/auditlog/admin-user-activities?`;
    }
    if (page) {
      if (first) {
        url = `${url}page=${page}`;
        first = false;
      } else url = `${url}&page=${page}`;
    }
    if (pageSize) {
      if (first) {
        url = `${url}pageSize=${pageSize}`;
        first = false;
      } else url = `${url}&pageSize=${pageSize}`;
    }
    if (searchValue) {
      if (first) {
        url = `${url}searchValue=${searchValue}`;
        first = false;
      } else url = `${url}&searchValue=${searchValue}`;
    }
    if (from) {
      if (first) {
        url = `${url}from=${from}`;
        first = false;
      } else url = `${url}&from=${from}`;
    }
    if (to) {
      if (first) {
        url = `${url}to=${to}`;
        first = false;
      } else url = `${url}&to=${to}`;
    }
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          let result = this._encryptDecrypt.decrypt(res.data);
          this.adminAuditLogSubject.next(result);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }
}
