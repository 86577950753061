import { Component, OnInit } from '@angular/core';
import {
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { AccountService } from './services/account.service';

import AOS from 'aos'; //AOS - 1
import { AdminService } from './services/admin.service';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from './services/toaster.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { AuthService } from './services/auth.service';
import { AccountingService } from './services/accounting.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'atlas-admin';

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  alertMsg: boolean = false;

  alertType: string = '';
  alertMessage: string = '';
  private alertSubscription!: Subscription;
  dateRangeForm: FormGroup;

  constructor(
    private router: Router,
    private _account: AccountService,
    private _admin: AdminService,
    private _accounting: AccountingService,
    private calendar: NgbCalendar,
    private toasterService: ToasterService,
    private bnIdle: BnNgIdleService,
    private _toaster: ToasterService,
    private _auth: AuthService,
    private fb: FormBuilder
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        // Show progress spinner or progress bar
        // //console.log('Route change detected');
      }

      if (event instanceof NavigationEnd) {
        // Hide progress spinner or progress bar
        // //console.log(event.url);

        //console.log(event.url)
        if (
          event.url === '/base/app/account/overview/personal/tier-1' ||
          event.url === '/base/app/account/overview/personal/tier-2'
        ) {
          //console.log(true);
          this._account.navigateSubject.next(true);
        } else {
          this._account.navigateSubject.next(false);
        }
        if (
          event.url === '/base/app/settings/overview/create-user' ||
          event.url === '/base/app/account/overview/edit-user'
        ) {
          //console.log(true);
          this._admin.navigateSubject.next(true);
        } else {
          this._admin.navigateSubject.next(false);
        }
        if (
          event.url === '/base/app/accounting' ||
          event.url === '/base/app/accounting/overview/central-parameter' ||
          event.url === '/base/app/accounting/overview/module' ||
          event.url === '/base/app/accounting/overview/category'
        ) {
          //console.log(event.url)
          //console.log(true);
          this._accounting.navigateSubject.next(true);
        } else {
          this._accounting.navigateSubject.next(false);
        }
      }

      if (event instanceof NavigationError) {
        // Hide progress spinner or progress bar
        // Present error to user
        //console.log(event.error);
      }

      this.dateRangeForm = this.fb.group(
        {
          startDate: ['', [Validators.required]],
          endDate: ['', [Validators.required]],
        },
        { validators: this.dateRangeValidator }
      );
    });

    AOS.init(); //AOS - 2
    AOS.refresh(); //refresh method is called on window resize and so on, as it doesn't require to build new store with AOS elements and should be as light as possible.

    this.fromDate = calendar.getToday();
    this.toDate = calendar.getToday();

    this.toasterService.modalCalendarSubject
      .asObservable()
      .pipe()
      .subscribe((data: any) => {
        this.alertMsg = data;

        this.fromDate = this.calendar.getToday();
        this.toDate = this.calendar.getToday();
      });
  }

  ngOnInit(): void {
    // 120 => 2min
    this.bnIdle.startWatching(120).subscribe((res) => {
      if (res) {
        // //console.log('session expired');
        this._auth.logout();
        this.router.navigate(['login']);
        // window.location.reload();
        return;
      }
    });
    this.alertSubscription = this._toaster.getAlert().subscribe((alert) => {
      this.alertType = alert.type;
      this.alertMessage = alert.message;
    });
  }

  clearAlert() {
    this._toaster.alert('', '');
  }
  ngOnDestroy() {
    // Unsubscribe from the alert observable to avoid memory leaks
    if (this.alertSubscription) {
      this.alertSubscription.unsubscribe();
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    // this.toasterService.onCalendar(date);
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  onModal(res) {
    this.toasterService.onCalendar(res);
  }

  onSend() {
    let date = {
      startDate: `${this.fromDate.year}-${String(this.fromDate.month).padStart(
        2,
        '0'
      )}-${String(this.fromDate.day).padStart(2, '0')}`,
      endDate: `${this.toDate.year}-${String(this.toDate.month).padStart(
        2,
        '0'
      )}-${String(this.toDate.day).padStart(2, '0')}`,
    };
    //console.log(date);
    this.toasterService.onCalendar(date);
  }

  // Custom Validator
  dateRangeValidator(form: FormGroup) {
    const startDate = form.get('startDate')?.value;
    const endDate = form.get('endDate')?.value;

    if (startDate && endDate) {
      return new Date(startDate) <= new Date(endDate)
        ? null
        : { dateRangeInvalid: true };
    }
    return null;
  }

  // Utility function to display validation messages
  hasError(field: string, error: string) {
    const control = this.dateRangeForm.get(field);
    return control?.touched && control?.hasError(error);
  }

  // Submit form handler
  onSubmit() {
    if (
      this.validateDates(
        this.dateRangeForm.value.startDate,
        this.dateRangeForm.value.endDate
      )
    ) {
      if (this.dateRangeForm.valid) {
        console.log('Form Submitted', this.dateRangeForm.value);
        this.toasterService.onCalendar(this.dateRangeForm.value);
      } else {
        this.dateRangeForm.markAllAsTouched();
      }
    } else {
      this.dateRangeForm.markAllAsTouched();
    }
  }

  validateDates(startDate: string, endDate: string): boolean {
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Check if the end date is less than the start date
    if (end < start) {
      console.log('End date is less than start date.');
      return false;
    } else {
      console.log('End date is valid.');
      return true;
    }
  }
}
