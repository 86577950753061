import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ErrorLoggingService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  info(...data: any[]): void {
    if (!environment.production) {
      console.log(data);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error(...data: any[]): void {
    if (!environment.production) {
      console.error(data);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  warn(...data: any[]): void {
    if (!environment.production) {
      console.warn(data);
    }
  }
}
