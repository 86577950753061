import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToastComponent {
  constructor() {
  }


  @Input() type: string = '';
  @Input() message: string = '';
  @Output() onDismiss = new EventEmitter<void>();

  dismissAlert() {
    this.onDismiss.emit();
  }

  onCancel() {
    this.onDismiss.emit();
  }
}
