import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  public toasterSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public modalSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public modalDeleteSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public modalRoleSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public modalCalendarSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public calendarResSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private alertSubject = new BehaviorSubject<{ type: string; message: string }>({
    type: '',
    message: ''
  });

  
  modal: boolean = false;
  calendar: boolean = false;
  constructor() { }


  alert(type: any, message: string, duration: number = 5000) {
    this.alertSubject.next({ type, message });

    // Set a timeout to clear the alert after the specified duration
    setTimeout(() => {
      this.alertSubject.next({ type: '', message: '' });
    }, duration); // Default duration is 5 seconds (5000 ms)
  }
  getAlert(): Observable<{ type: string; message: string }> {
    return this.alertSubject.asObservable();
  }

  onModal(data) {
    this.modal = !this.modal;

    if (data == 'delete') {
      this.modalDeleteSubject.next(this.modal)
      return
    }
    if (data == 'role') {
      this.modalRoleSubject.next(this.modal)
      return
    }
    this.modalSubject.next(this.modal)

  }

  onCalendar(data: any) {
    this.calendar = !this.calendar;
    this.modalCalendarSubject.next(this.calendar);
    if (data) {
      this.calendarResSubject.next(data)
      return;
    }
  }


}
