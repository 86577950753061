import { Component } from '@angular/core';
import {
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ErrorLoggingService } from 'src/app/services/logging/error-logging.service';
import { SidebarService } from 'src/app/services/sidebar.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  loginData: any[] = [];
  publicSideBar: any[] = [];

  constructor(
    private _auth: AuthService,
    public sidebarservice: SidebarService,
    private error: ErrorLoggingService,
    private router: Router
  ) {}

  logout() {
    this._auth.logout();
  }

  toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
  }

  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  hideSidebar() {
    this.sidebarservice.setSidebarState(true);
  }

  sidebar = this.sidebarservice.sidebarState();

  ngOnInit() {
    this._auth.loginSubject.asObservable().subscribe({
      next: (res) => {
        // console.log(res?.result?.permissions);
        // console.log(this.sidebar);
        this.loginData = res?.result?.permissions;

        this.dolooping();
      },
      error: (err) => {
        // //console.log(err.error);
      },
    });
  }
  // d0f8791a_S

  checker(arr, item) {
    let res = arr.map((r) => {
      let respon = false;
      if (r === item[0] || r === item[1]) {
        respon = true;
      }
      return respon;
    });
    //console.log(res)
    return res;
  }
  handleClick(event: MouseEvent, menuIndex) {
    event.stopPropagation();
    // Your logic here
  }

  dolooping() {
    for (let i = 0; i < this.sidebar?.length; i++) {
      for (let j = 0; j < this.loginData?.length; j++) {
        if (
          this.loginData[j] === this.sidebar[i].permissionStatus[1] ||
          this.loginData[j] === 'AccessAll'
        ) {
          this.publicSideBar.push(this.sidebar[i]);
          break; // Exit the inner loop after a match is found
        }
      }
    };
    this.error.info('sidebar',this.publicSideBar, 'mainSidebar', this.sidebar, 'loginData', this.loginData)
    
  }
}
