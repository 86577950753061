import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  name: string;
  loginData: any;
  constructor(private _auth: AuthService) {
    _auth.titleSubject.asObservable().subscribe((res: any) => {
      this.name = res;
    });

    _auth.loginSubject.asObservable().subscribe({
      next: (res) => {
        // //this.error.info(res?.result);
        this.loginData = res?.result;
      },
      error: (err) => {
        // //console.log(err.error);
      },
    });
  }
}
