<section>
  <div class="toaster fade-in-image" [class]="type" [class.active]="type">
    <div class="toast-content">
      <span [ngSwitch]="type">
        <span *ngSwitchCase="'success'">
          <i class="fas fa-soild check fa-check"></i>
        </span>
        <span *ngSwitchCase="'error'">
          <i class="fas fa-soild check fa-xmark"></i
        ></span>
        <span *ngSwitchCase="'warning'">
          <i class="fas fa-soild check fa-triangle-exclamation"></i
        ></span>
        <span *ngSwitchDefault></span>
      </span>

      <div class="message">
        <span class="text text-1" [ngSwitch]="type">
          <span *ngSwitchCase="'success'">Success</span>
          <span *ngSwitchCase="'error'">Error</span>
          <span *ngSwitchCase="'warning'">Warning</span>
          <span *ngSwitchDefault></span>
        </span>
        <span class="text text-2" [innerHTML]="message"></span>
      </div>
    </div>
    <i class="fa-solid fa-xmark close" (click)="onCancel()"></i>

    <div class="progress" [class.active]="type"></div>
  </div>
</section>
