<app-toast [type]="alertType" 
[message]="alertMessage" 
(onDismiss)="clearAlert()"></app-toast>

<router-outlet></router-outlet>

<div
  class="modal fade"
  [ngClass]="alertMsg ? 'show' : ''"
  [style]="alertMsg ? 'display: block;' : 'display: none'"
  id="staticBackdrop"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <div class="modal-header-header"> -->

        <button
          type="button"
          class="btn-close"
          (click)="onModal('')"
          aria-label="Close"
        ></button>
        <!-- </div> -->
      </div>
      <div class="modal-body">
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <button class="nav-link" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Custom Date</button>
            <button class="nav-link active" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Date</button>
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
            <div>
              <ngb-datepicker
                #dp
                (dateSelect)="onDateSelection($event)"
                [displayMonths]="2"
                [dayTemplate]="t"
                [navigation]="'select'"
                outsideDays="visible"
              >
              </ngb-datepicker>
    
              <ng-template #t let-date let-focused="focused">
                <span
                  class="custom-day"
                  [class.focused]="focused"
                  [class.range]="isRange(date)"
                  [class.faded]="isHovered(date) || isInside(date)"
                  (mouseenter)="hoveredDate = date"
                  (mouseleave)="hoveredDate = null"
                >
                  {{ date.day }}
                </span>
              </ng-template>
    
              <!-- <hr /> -->
              <div class="calendar_btn">
                <button
                class="btn btn-sm btn-light-green"
                (click)="onSend()"
              >
                Done
              </button>
              </div>
              <!-- <pre>From: {{ fromDate | json }} </pre>
              <pre>To: {{ toDate | json }} </pre> -->
            </div>
          </div>
          <div class="tab-pane fade show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
            <form [formGroup]="dateRangeForm" (ngSubmit)="onSubmit()" class="needs-validation" novalidate>
              <div class="mb-3">
                <label for="startDate" class="form-label">Start Date</label>
                <input
                  id="startDate"
                  type="date"
                  class="form-control"
                  [class.is-invalid]="hasError('startDate', 'required')"
                  formControlName="startDate"
                />
                <div *ngIf="hasError('startDate', 'required')" class="invalid-feedback">
                  Start Date is required.
                </div>
              </div>
            
              <div class="mb-3">
                <label for="endDate" class="form-label">End Date</label>
                <input
                  id="endDate"
                  type="date"
                  class="form-control"
                  [class.is-invalid]="hasError('endDate', 'required') || dateRangeForm.errors?.['dateRangeInvalid']"
                  formControlName="endDate"
                />
                <div *ngIf="hasError('endDate', 'required')" class="invalid-feedback">
                  End Date is required.
                </div>
                <div *ngIf="dateRangeForm.errors?.['dateRangeInvalid']" class="invalid-feedback">
                  End Date must be after the Start Date.
                </div>
              </div>
              <div class="calendar_btn">
                <button type="submit" class="btn btn-sm btn-light-green">Submit</button>
              </div>
            </form>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div>
